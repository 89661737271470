import { Transaction } from "@canopyinc/api-docs/types/ts/Transaction.type";
import { Button, Card, DataGrid, DataGridColumn, FilterChange, Text } from "@canopyinc/aura";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/pro-solid-svg-icons";
import Link from "next/link";

import { DrawerType } from "@/context/AccountDrawersContext/types";
import { CanopyOsI18NSchema } from "@/context/i18n/types/generatedTypes";
import { CanopyOsPluginsSchema } from "@/context/plugins/types";
import { TPaginationSummary } from "@/hooks";
import { RecursivePartial } from "@/types";

import { TransactionDropdown } from "./TransactionDropdown";
import { maybeBuildAdjustmentRows } from "./utils";

export type TransactionTableProps = {
  accountId: string;
  columns: DataGridColumn[];
  dictionary?: RecursivePartial<CanopyOsI18NSchema["components"]["transactions_view"]["list"]>;
  loading?: boolean;
  onClick?: (action: Partial<DrawerType>) => void;
  onFilter?: (search: FilterChange) => void;
  onSelect?: (transaction: Transaction, expectedDrawer: Partial<DrawerType>) => void;
  pagination?: TPaginationSummary;
  plugins?: RecursivePartial<CanopyOsPluginsSchema["components"]["transactions_view"]["list"]>;
  search?: string;
  showRecentOnly?: boolean;
  timeZone?: string;
  transactions?: Transaction[];
};

export type TransactionFieldProps = {
  transaction: Transaction;
};

export const TransactionTable = ({
  accountId,
  columns,
  dictionary,
  showRecentOnly = false,
  loading,
  onClick,
  onSelect,
  plugins,
  timeZone,
  transactions,
}: TransactionTableProps) => {
  const rows = (transactions ?? [])
    .map((transaction) =>
      maybeBuildAdjustmentRows({
        DropdownComponent: () => (
          <TransactionDropdown
            dictionary={dictionary?.dropdown}
            onClick={onClick}
            onSelect={onSelect}
            plugins={plugins?.dropdown}
            transaction={transaction}
          />
        ),
        showDateHint: true,
        timeZone,
        tooltip: dictionary?.fields?.date?.tooltip,
        transaction,
      })
    )
    .flat();

  const recentTransactionsTitle = dictionary?.recent_transactions_title ?? "Recent Transactions";
  const historyTitle = dictionary?.title ?? "Transaction History";
  const title = showRecentOnly ? recentTransactionsTitle : historyTitle;

  return (
    <div data-testid="transaction-table">
      <div className="flex justify-between items-center align-center pb-4">
        <Text as="h3">{title}</Text>
        {showRecentOnly ? (
          <Link href={{ pathname: `/app/accounts/${accountId}/transactions` }} passHref>
            <Button
              as="div"
              size="sm"
              classNames={{ button: "cursor-pointer" }}
              icon={<FontAwesomeIcon aria-hidden="true" icon={faMagnifyingGlass} />}
            >
              {dictionary?.actions?.view_all ?? "View All Transactions"}
            </Button>
          </Link>
        ) : null}
      </div>
      <Card
        classNames={{
          card: "overflow-visible relative",
          body: "bg-transparent",
        }}
      >
        <DataGrid
          classNames={{
            card: "!rounded-2xl",
            table: "!rounded-2xl",
            tableWrapper: "!rounded-2xl !overflow-visible !md:overflow-visible",
            headerThead: "rounded-2xl",
          }}
          columns={columns}
          loading={loading}
          // Prevent table row click interactions so we can open the context mneu
          // onRowClick={() => {}}
          rows={rows}
          testid="transaction"
          emptyState={{
            callout: dictionary?.empty_state ?? "No balance splits found.",
          }}
        />
      </Card>
    </div>
  );
};
