import { BalanceSplit } from "@canopyinc/api-docs/types/ts/BalanceSplit.type";
import { Loan } from "@canopyinc/api-docs/types/ts/Loan.type";
import { Transaction } from "@canopyinc/api-docs/types/ts/Transaction.type";
import { isAfter, isBefore } from "date-fns";
import { useState } from "react";

import { LINE_ITEM_TYPES } from "@/constants";
import { GetTransactionSplitsQuery } from "@/api/accounts/useTransactionSplits";
import { BaseBalanceSplits, BalanceSplits } from "./BalanceSplits";
import { BaseLoanSummary, LoanSummary } from "./LoanSummary";
import { Account, AmortizationSchedule as AmSchedule, Product } from "@/types";
import { ReversalSummary } from "./ReversalSummary";
import { TransactionAdjustments } from "./TransactionAdjustments";
import { TransactionSummary } from "./TransactionSummary";
import { TransactionsImpact } from "./TransactionsImpact";
import { RepaymentProgress } from "./RepaymentProgress";
import { AmortizationSchedule } from "./AmortizationSchedule";
import { ExternalFields } from "./ExternalFields";

export type TransactionDetailsProps = {
  account: Account;
  adjustment?: Transaction; // selected adjustment
  adjustments?: Transaction[]; // adjustments for transaction
  balanceSplits?: BalanceSplit[];
  balanceSplitMetadata?: {
    balance_repour_dates: string[];
  };
  error: boolean;
  impact?: Transaction[];
  impactLoading: boolean;
  loading: boolean;
  loan?: Loan;
  onSelectAdjustmentId?: (adjustmentId: string) => void;
  onSelectLoanId?: (loanId: string) => void;
  onSelectTransactionId?: (transactionId: string) => void;
  schedule?: AmSchedule[];
  timeZone?: string;
  transaction?: Transaction; // selected transaction
};

export const manuallyFilterSplits = (filters: GetTransactionSplitsQuery, splits?: BalanceSplit[]) => {
  if (!splits) {
    return [];
  }
  let output = splits;
  if (filters.active_before != null) {
    // Filter out splits that are:
    // 1. issued before the date
    // 2. discarded at or after the date
    output = output.filter(
      (split) =>
        !isAfter(new Date(split.issued_at), new Date(filters.active_before!)) &&
        !isBefore(new Date(split.discarded_at!), new Date(filters.active_before!))
    );
  }
  if (filters.effective_at_after != null) {
    output = output.filter((split) => !isBefore(new Date(split.effective_at), new Date(filters.effective_at_after!)));
  }
  if (filters.effective_at_before != null) {
    output = output.filter((split) => !isAfter(new Date(split.effective_at), new Date(filters.effective_at_before!)));
  }
  if (filters.issued_at_after != null) {
    output = output.filter((split) => !isBefore(new Date(split.issued_at), new Date(filters.issued_at_after!)));
  }
  if (filters.issued_at_before != null) {
    output = output.filter((split) => !isAfter(new Date(split.issued_at), new Date(filters.issued_at_before!)));
  }
  if (filters.is_discarded != null) {
    output = output.filter((split) =>
      filters.is_discarded ? split.discarded_by_transaction_id != null : split.discarded_by_transaction_id == null
    );
  }

  return output;
};

export const TransactionDetails = (props: TransactionDetailsProps) => {
  const { adjustments, balanceSplits, balanceSplitMetadata, transaction } = props;
  const { balance_repour_dates: repourDates } = balanceSplitMetadata ?? {};
  const [filteredBalanceSplits, setFilteredBalanceSplits] = useState(balanceSplits ?? []);

  const hasBalance =
    transaction?.transaction_super_type != null &&
    ["FEE", "INTEREST", "PRINCIPAL"].includes(transaction.transaction_super_type);
  const isReversal = transaction?.transaction_type === LINE_ITEM_TYPES.PAYMENT_REVERSAL;
  const isCapitalizationParentLineItem =
    transaction?.transaction_type === LINE_ITEM_TYPES.CAPITALIZATION_OF_INTEREST_AND_FEES;
  const showBalance = hasBalance && !isReversal && !isCapitalizationParentLineItem;
  const hasAdjustments = adjustments != null && adjustments.length > 0;
  const hasReversal =
    transaction?.reversed_by_transaction_id != null || transaction?.adjustment_by_transaction_id != null;

  const { loan, onSelectLoanId, onSelectTransactionId } = props;

  // Hack to substitute the refetching behavior on filter with a manual filter on the balance splits array
  const BalanceSplitsComponent =
    props?.balanceSplits != null && props?.balanceSplitMetadata != null
      ? () => (
          <BaseBalanceSplits
            {...props}
            splits={filteredBalanceSplits ?? []}
            repourDates={repourDates ?? []}
            onFilter={(filters) => setFilteredBalanceSplits(manuallyFilterSplits(filters, balanceSplits))}
            onSelect={(split) => {
              if (split.applied_to_transaction_type === LINE_ITEM_TYPES.LOAN) {
                return onSelectLoanId?.(split.applied_to_transaction_id);
              }
              // Hack for payments in LoanLab
              if (split.balance_split_summary === "Payment") {
                return onSelectTransactionId?.(split.origination_transaction_id);
              }
              return onSelectTransactionId?.(split.applied_to_transaction_id);
            }}
          />
        )
      : () => <BalanceSplits {...props} />;

  const LoanSummaryComponent =
    props?.loan != null
      ? () => <BaseLoanSummary {...props} loan={loan} title="Loan Details" />
      : () => <LoanSummary {...props} />;

  return (
    <div className="flex flex-col gap-6">
      <TransactionSummary {...props} showBalance={showBalance} showHint={false} />
      {isReversal ? <TransactionsImpact {...props} /> : <BalanceSplitsComponent />}
      {hasAdjustments ? <TransactionAdjustments {...props} /> : null}
      {transaction?.belongs_to_loan_id != null ? <LoanSummaryComponent /> : null}
      {/* These two components replicate v1 behavior of loan details drawer */}
      {/* This is needed as new loan drawer is v2 only */}
      {transaction?.transaction_type === "LOAN" ? <RepaymentProgress {...props} /> : null}
      {transaction?.transaction_type === "LOAN" && props.schedule != null ? <AmortizationSchedule {...props} /> : null}
      {hasReversal ? <ReversalSummary {...props} /> : null}
      <ExternalFields {...props} />
    </div>
  );
};
